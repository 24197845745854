//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import forEach from 'lodash/forEach'
import extend from 'lodash/extend'
import UppyModal from './UppyModal'
const EnLocale = require('@uppy/locales/lib/en_US')
const SrLocale = require('@uppy/locales/lib/sr_RS_Latin')
const FrLocale = require('@uppy/locales/lib/fr_FR')

SrLocale.strings = extend({}, SrLocale.strings, {
  browse: 'izaberite',
  dropHereOr: 'Prevucite fajl ovde ili %{browse}',
  dropHint: 'Prevucite fajl ovde',
  dropPasteBoth: 'Prevucite fajl ovde ili %{browse}',
  dropPasteFiles: 'Prevucite fajl ovde ili %{browse}',
  dropPasteFolders: 'Prevucite fajl ovde ili %{browse}',
  dropPasteImportBoth: 'Prevucite fajl ovde ili %{browse}',
  dropPasteImportFiles: 'Prevucite fajl ovde ili %{browse}',
  dropPasteImportFolders: 'Prevucite fajl ovde ili %{browse}',
  retryUpload: 'Pokušaj ponovo da pošalješ',
  timedOut: 'Prekidamo preuzimanje jer je zastalo. Broj sekundi zastoja: %{seconds}.'
})
// import { DashboardModal } from '@uppy/vue'

export default {
  name: 'FileInput',
  components: {
    UppyModal
  },
  $_veeValidate: {
    name () {
      return this.name
    },
    value () {
      return this.value
    }
  },
  props: {
    value: {
      type: [Object, Array],
      default: null,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    uppyLocales: {
      en: EnLocale,
      sr: SrLocale,
      fr: FrLocale
    },
    uploadOpen: false,
    xhrPlugin: null,
    retried: false,
    uppy: null
  }),

  computed: {
    locale () {
      return this.$i18n.localeProperties.code
    }
  },

  mounted () {
    this.createUppy()
    this.getCSRF()
  },

  beforeDestroy () {
    if (this.uppy === null) {
      return
    }
    this.uppy.off('dashboard:modal-open', this.onModalOpen)
    this.uppy.off('dashboard:modal-closed', this.onModalClosed)
  },

  methods: {
    async openModal () {
      await this.getCSRF()
      const newCSRF = this.getXSRFToken()
      if (!this.xhrPlugin) {
        return
      }
      this.xhrPlugin.setOptions({
        headers: {
          'X-XSRF-TOKEN': newCSRF
        }
      })
      this.uploadOpen = true
    },
    async removeFile (file) {
      await this.$axios.get('/sanctum/csrf-cookie')
      try {
        this.$axios.delete(`/api/data/files/${file.id}`)
        this.updateValue(file, 'remove')
      } catch (e) {

      }
    },
    updateValue ($event, action) {
      if (!this.multiple) {
        if (action === 'remove') {
          this.$emit('input', null)
          return
        }

        this.$emit('input', $event)
        return
      }

      let temp = []
      if (this.value && Array.isArray(this.value)) {
        forEach(this.value, (item) => {
          temp.push(item)
        })
      }
      if (action === 'add') {
        temp.push($event)
        this.$emit('input', temp)
        return
      }

      const index = temp.findIndex(item => item.id === $event.id)
      temp = temp.filter(item => item.id !== $event.id)

      if (action === 'replace') {
        if (index > -1) {
          temp.splice(index, 0, $event)
        } else {
          temp.push($event)
        }
      }

      this.$emit('input', temp)
    },
    onModalOpen () {
      this.uploadOpen = true
    },
    onModalClosed () {
      this.uploadOpen = false
    },
    onUploadSuccess (file, response) {
      this.updateValue(response.body.data, 'add')
    },
    createUppy () {
      this.uppy = new Uppy({
        autoProceed: true,
        allowMultipleUploads: false,
        locale: this.uppyLocales[this.locale],
        restrictions: {
          maxFileSize: 53687091200,
          minNumberOfFiles: 1,
          maxNumberOfFiles: this.multiple ? 5 : 1,
          allowedFileTypes: [
            'image/*',
            'text/csv',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/rtf',
            'text/plain',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ]
        }
      }).use(XHRUpload, {
        id: 'xhrUploadPlugin',
        limit: 10,
        endpoint: process.env.CG_URL + '/api/data/files',
        formData: true,
        fieldName: 'file',
        withCredentials: true,
        headers: {
          'X-XSRF-TOKEN': this.getXSRFToken()
        }
      })
      this.xhrPlugin = this.uppy.getPlugin('xhrUploadPlugin')
      this.uppy.on('dashboard:modal-open', this.onModalOpen)
      this.uppy.on('dashboard:modal-closed', this.onModalClosed)
      this.uppy.on('upload-success', this.onUploadSuccess)
    },
    async getCSRF () {
      try {
        await this.$axios.get('/sanctum/csrf-cookie')
        return this.getXSRFToken()
      } catch (e) {

      }
    },
    getCongraphToken () {
      return decodeURIComponent(this.getCookie('congraph_session'))
    },
    getXSRFToken () {
      return decodeURIComponent(this.getCookie('XSRF-TOKEN'))
    },
    getCookie (name) {
      const nameEQ = name + '='
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') { c = c.substring(1, c.length) }
        if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length) }
      }
      return null
    }
  }
}
