/* eslint-disable no-undef */
/* eslint-disable camelcase */
import Vue from 'vue'
import {
  localize,
  extend,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

// @TODO select locale dynamically
import enLocaleMessages from 'vee-validate/dist/locale/en.json'
import srLocaleMessages from 'vee-validate/dist/locale/sr_Latin.json'
import frLocaleMessages from 'vee-validate/dist/locale/fr.json'

/**
 * Just comment / uncomment rules that you want in this project
 */

import * as rules from 'vee-validate/dist/rules'

// PLUGIN
const CustomValidation = {
  install (Vue) {
    Object.keys(rules).forEach((rule) => {
      // eslint-disable-next-line import/namespace
      extend(rule, rules[rule])
    })

    extend('strongPassword', {
      getMessage: field => `The password must be at least 8 characters long and contain at least one of each: uppercase letter, lowercase letter, number, and special character (E.g. , . _ & ?).`,
      validate: value => {
        var strongRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.,<>;:()\-_\+={}\[\]|\\"'?~`±§/])(?=.{8,})/);
        return strongRegex.test(value);
      }
    })

    srLocaleMessages.messages.is = 'Polje {_field_} je obavezno'
    frLocaleMessages.messages.is = 'Le champ {_field_} est obligatoire'
    enLocaleMessages.messages.strongPassword = 'The password must be at least 8 characters long and contain at least one of each: uppercase letter, lowercase letter, number, and special character (E.g. , . _ & ?).'
    srLocaleMessages.messages.strongPassword = 'The password must be at least 8 characters long and contain at least one of each: uppercase letter, lowercase letter, number, and special character (E.g. , . _ & ?).'
    frLocaleMessages.messages.strongPassword = 'Le mot de passe doit comporter au moins 8 caractères et contenir au moins un de chacun: lettre majuscule, lettre minuscule, numéro et caractère spécial (par exemple,. _ &?).'

    localize({
      en: enLocaleMessages,
      sr: srLocaleMessages,
      fr: frLocaleMessages
    })
    localize('en')

    // Regsiter components globally
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
}

Vue.use(CustomValidation)
