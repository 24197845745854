//
//
//
//
//
//
//
//
//

export default {
  name: 'ChangeLanguageLink',
  props: {
    locale: {
      type: Object,
      required: true
    }
  },

  computed: {
    linkComponent () {
      if (this.locale.code === this.$i18n.localeProperties.code) {
        return 'span'
      }

      return 'nuxt-link'
    }
  }
}
