//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppFooterNav from '@/components/app/AppFooterNav'

export default {
  name: 'AppFooter',
  components: {
    AppFooterNav
  },
  computed: {
    // locales () {
    //   return this.$store.getters.locales
    // },
    menuNavItems () {
      if (!this.$auth.loggedIn) {
        return []
      }
      return [
        {
          to: this.localePath('/'),
          text: this.$t('menu.header.Procurement')
        },
        {
          to: this.localePath('/en/vendor-registration'),
          text: this.$t('menu.header.vendorRegistration')
        }
      ]
    },
    footerMenuNavItems () {
      return [
        {
          to: this.localePath('/en/terms-and-conditions'),
          text: this.$t('menu.footer.Terms and Conditions')
        },
        {
          to: this.localePath('/en/privacy-policy'),
          text: this.$t('menu.footer.Privacy Policy')
        }
      ]
    }
  }
}
