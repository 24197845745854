//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'AppNavbar',
  data: () => ({
    hideAfter: 64,
    isHidden: true
  }),
  computed: {
    ...mapState([
      'isHeroLight'
    ]),
    themeClass () {
      return this.isHeroLight ? 'light' : 'dark'
    },
    menuNavItems () {
      if (!this.$auth.loggedIn) {
        return []
      }
      return [
        {
          to: this.localePath('/'),
          text: this.$t('menu.header.Procurement')
        },
        {
          to: this.localePath('/en/vendor-registration'),
          text: this.$t('menu.header.vendorRegistration')
        }
      ]
    },
  }
}
