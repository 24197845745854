//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import AppNavbar from '~/components/app/AppNavbar'

export default {
  name: 'AppHeader',
  components: {
    AppNavbar
  },
  data: () => ({
    maindisplay: false,
    topdisplay: true
  }),
  computed: {
    ...mapState([
      'isMainMenuOpen'
    ]),
    homeLink () {
      return this.localePath('/')
    },
    profileUrl () {
      return this.localePath('/en/account-settings')
    },
    signinUrl () {
      return this.localePath('/en/sign-in')
    },
    signupUrl () {
      return this.localePath('/en/sign-up')
    },
    locale () {
      return this.$i18n.localeProperties
    },
    locales () {
      return this.$i18n.locales
    }
  },
  methods: {
    async onLogout () {
      await this.$auth.logout()
      // this.$router.replace('/')
    },
    toggleMainMenu () {
      this.$store.commit('SET_STATE', {
        prop: 'isMainMenuOpen',
        value: !this.isMainMenuOpen
      })
    }
  }
}
